@use "../globals" as *;

.chat {
  display: flex;
  flex-direction: column;
  .user,
  .curr-user {
    display: inline-block;
    position: relative;
    width: max-content;
    max-width: 60%;
    background-color: rgb(76, 93, 214, 0.8);
    border-radius: 5rem;
    margin: 0.25rem 0;
    padding: 1.5rem;
    overflow-wrap: break-word;
    .img-wrapper {
      @include flexCenter(row);
      background-color: rgb(76, 93, 214, 0.8);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-105%, -50%);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .curr-user {
    background-color: rgb(0, 152, 198, 0.8);
    margin-right: 0;
    margin-left: auto;
    .img-wrapper {
      left: unset;
      transform: translate(105%, -50%);
      right: 0;
      background-color: rgb(0, 152, 198, 0.8);
    }
  }
}

@media (max-width: $bp-phone) {
  .chat {
    .user,
    .curr-user {
      max-width: 100%;
      padding: 0.7rem;
      font-size: 0.7rem;
      .img-wrapper {
        width: 30px;
        height: 30px;
      }
    }
  }
}

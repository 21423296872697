@use "../globals" as *;

.alert-danger {
  color: $clr-red-dark;
  background-color: $clr-red-light;
  padding: 1rem;
  border-radius: $radius-medium;
  text-transform: capitalize;
  font-weight: 500;
  position: absolute;
  left: 50%;
  top: 2rem;
  transform: translate(-50%, 0);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: -5;
  visibility: hidden;
  opacity: 0;
  &.active {
    z-index: 5;
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 50%);
  }
}

@use "../globals" as *;

.login-bcg {
  @include flexCenter(column);
  width: 100%;
  height: 100vh;
  background-image: url("../images/login-bcg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .login {
    @include flexCenter(column);
    background-color: rgb(0, 0, 0, 0.3);
    border-radius: $radius-large;
    padding: 4rem 8rem;
    width: $pageWidth;
    max-width: max-content;
    h1 {
      font-size: $heading-large;
      color: $clr-text-light;
      margin-bottom: 2rem;
    }
    .form-wrapper {
      form {
        @include flexCenter(column);
        gap: 1rem;
        label {
          display: flex;
          position: relative;
          input {
            border: none;
            background-color: rgb(255, 255, 255, 0.4);
            border: 1px solid transparent;
            color: white;
            padding: 1.25rem 1.5rem;
            padding-left: 4rem;
            border-radius: $radius-medium;
            font-size: $text;
            outline: none;
            @include transition(box-shadow);
            &::placeholder {
              color: white;
            }
            &:focus {
              box-shadow: 0 0 5px 1px $clr-accent-1;
            }
          }
          .user-message + input {
            border: 1px solid $clr-red-light;
            box-shadow: 0 0 5px 1px $clr-red-light;
          }
          .input-icon {
            display: flex;
            font-size: $heading-medium;
            color: white;
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .submit-form-btn {
          @include button($clr-text-light, $clr-accent-2);
          margin: 2rem 0;
          margin-top: 1rem;
        }
      }
      .google-acc-btn {
        @include button($clr-text, white);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $text;
        padding: 1rem 0;
        width: 100%;
        .icon {
          display: flex;
          font-size: $heading-medium;
          margin-right: 1rem;
        }
      }
    }
  }
  .switch-form {
    display: flex;
    color: $clr-text-light;
    font-size: $text-small;
    margin-top: 2rem;
    button {
      color: $clr-accent-1;
      font-size: $text-small;
      text-decoration: underline;
      margin-left: 0.5rem;
    }
  }
}

@media (max-width: $bp-phone) {
  .login-bcg {
    .login {
      padding: 1rem 2rem;
      h1 {
        font-size: $heading-small;
        margin-bottom: 0.5rem;
      }
      .form-wrapper {
        form {
          label {
            input {
              padding: 0.75rem 1.25rem;
              padding-left: 3.5rem;
              font-size: $text-xsmall;
              width: 100%;
            }
            .input-icon {
              font-size: $heading-small;
            }
          }
          .submit-form-btn {
            margin: 1rem 0;
            margin-top: 0.5rem;
            font-size: $text-xsmall;
            padding: 0.75rem 2rem;
          }
        }
        .google-acc-btn {
          font-size: 0.8rem;
          padding: 0.75rem 0;
          .icon {
            font-size: $text;
            margin-right: 1rem;
          }
        }
      }
    }
    .switch-form {
      flex-direction: column;
      align-items: center;
      font-size: $text-xsmall;
      bottom: 1rem;
      button {
        font-size: $text-xsmall;
        margin-left: 0.5rem;
      }
    }
  }
}

@use "../globals" as *;
.chat-wrapper {
  .message-form {
    flex: 1 -1%;
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
    label {
      flex: 3;
      display: flex;
      input {
        width: 100%;
        border: none;
        background-color: rgb(255, 255, 255);
        border: 1px solid transparent;
        padding: 1.25rem 1.5rem;
        border-radius: $radius-medium;
        font-size: $text;
        outline: none;
        @include transition(box-shadow);
        &::placeholder {
          color: white;
        }
        &:focus {
          box-shadow: 0 0 5px 1px $clr-accent-1;
        }
      }
    }
    .send-btn {
      flex: 1;
      max-width: max-content;
      @include button($clr-text-light, $clr-accent-2);
      font-size: $heading-small;
      padding: 1rem 2rem;
      align-items: center;
      transition: all 0.3s ease;
      overflow: hidden;
      .icon {
        font-size: $heading-medium;
        display: flex;
        position: relative;
        transition: all 0.3s ease;
        top: 0;
        right: 0;
      }
      &.animate-icon .icon {
        animation: flyerAnimate 0.5s forwards 1 ease;
      }
    }
    .arrow-down-btn {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -170%);
      font-size: $heading-medium;
      opacity: 0;
      z-index: -10;
      transition: transform 0.3s ease, opacity 0.3s ease;
      &.active {
        opacity: 1;
        z-index: 10;
        transform: translate(-50%, -150%);
      }
    }
  }
}

@keyframes flyerAnimate {
  0% {
    top: 0rem;
    right: 0rem;
  }
  33% {
    top: -2rem;
    right: -2rem;
    opacity: 0;
  }
  66% {
    opacity: 0;
    top: 2rem;
    right: 2rem;
  }
  100% {
    top: 0;
    right: 0;
  }
}

@media (max-width: $bp-phone) {
  .chat-wrapper {
    .message-form {
      flex: 1 -1%;
      display: flex;
      gap: 1rem;
      padding: 1rem 0;
      label {
        flex: 3;
        display: flex;
        input {
          width: 100%;
          padding: 0.75rem;
          font-size: $text-small;
        }
      }
      .send-btn {
        flex: 1;
        max-width: max-content;
        font-size: $text;
        padding: 1rem;
        .icon {
          font-size: $text;
          display: flex;
          + span {
            display: none;
          }
        }
      }
      .arrow-down-btn {
        font-size: $heading-small;
        padding: 0.5rem;
        padding-bottom: 0.25rem;
      }
    }
  }
}

@use "../globals" as *;

.chat-bcg {
  @include flexCenter(column);
  background: linear-gradient(
    50deg,
    rgba(0, 232, 232, 1) 0%,
    rgba(13, 61, 175, 1) 50%
  );
  width: 100%;
  height: 100vh;
}

.chat-wrapper {
  display: flex;
  flex-direction: column;
  width: $pageWidth;
  max-width: 800px;
  height: 100vh;

  & *:not(input) {
    color: $clr-text-light;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 -1%;
    h1 {
      display: flex;
      align-items: center;
    }
    img {
      width: 40px;
      margin-right: 0.5rem;
    }
    .logout-btn {
      display: flex;
      align-items: center;
      font-size: $heading-small;
      gap: 0.25rem;
      padding: 1rem;
      padding-right: 0;
      @include transition(color);
      .icon {
        display: flex;
        @include transition(transform);
      }
      &:hover {
        color: $clr-accent-1;
        .icon {
          transform: translateX(50%);
        }
      }
    }
  }
  .chat {
    min-height: 70vh;
    height: 80vh;
    flex: 1;
    overflow: auto;
    position: relative;
    background-color: rgb(255, 255, 255, 0.1);
    border-radius: $radius-large;
    padding: 1rem 4rem;
    font-weight: 500;

    &::-webkit-scrollbar {
      width: 0.75rem;
      margin: 1rem 0;
    }

    &::-webkit-scrollbar-track {
      margin: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(196, 196, 196, 0.5);
      border-radius: $radius-large;
    }
  }
}

@media (max-width: $bp-phone) {
  .chat-wrapper {
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1 -1%;
      .logout-btn {
        font-size: $text-small !important;
      }
      h1 {
        display: flex;
        align-items: center;
        font-size: $heading-small;
      }
      img {
        width: 30px;
        margin-right: 0.5rem;
      }
      .logout-btn {
        font-size: $heading-xsmall;
      }
    }
    .chat {
      &::-webkit-scrollbar {
        width: 0rem;
        margin: 0;
      }
      padding: 1rem 2rem;
    }
  }
}
